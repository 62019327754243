import Vue from 'vue'
import Vuex from 'vuex'
import { AUTH_TOKEN } from '../../helpers/globals'
import axios from 'axios'
Vue.use(Vuex)
import { filter, uniqueId, cloneDeep, maxBy, size, findIndex, concat } from 'lodash'

const timePeriodFilters = [
  {
    label: '1 month',
    months: 1,
    active: true

  },
  {
    label: '3 months',
    months: 3,
    active: false

  },
  {
    label: '6 months',
    months: 6,
    active: false,
  },
  {
    label: '12 months',
    months: 12,
    active: false
  }
]

export default new Vuex.Store({
  state: {
    periodSelectorFilters: cloneDeep(timePeriodFilters),
    metrics: [],
    departments: [],
    analysisGroups: [],
    components: [],
    npsComponents: [],
    rollingMetrics: [],
    rollingComponents: [],
    rollingResponseRates: [],
    currentPeriod: "",
    selectedDepartments: [],
    filteredDepartments: [],
    selectedDepartmentIds: [],
    selectedAnalysisGroups: [],
    filteredAnalysisGroups: [],
    selectedAnalysisGroupIds: []
  },

  mutations: {
    changePeriod(state, newTimePeriod) {
      state.periodSelectorFilters.forEach ( tp => tp.active = false )
      newTimePeriod.active = true
    }
  },

  getters: {},

  actions: {
    fetchRollingMetrics({state, commit}, period) {
      $.get('/analysis/comparison/rolling_metrics', { period_months: period.months } , function(data) {
        state.rollingMetrics = data
      }).then(({ data }) => commit('changePeriod', period))
    },

    fetchRollingComponents({state, commit}, period) {
      $.get('/analysis/comparison/rolling_components', { period_months: period.months } , function(data) {
        state.rollingComponents = data
      }).then(({ data }) => commit('changePeriod', period))
    },

    postFilterData({ commit, state }, data) {
      const departmentIds = data.department_ids
      const groupIds = data.analysis_group_ids
      state.selectedDepartmentIds = departmentIds
      state.selectedAnalysisGroupIds = groupIds

      axios.post(
        '/sessions/filtered_object_ids',
        {
          authenticity_token: AUTH_TOKEN,
          department_ids: departmentIds,
          analysis_group_ids: groupIds
        }
      )
    },

    fetchRollingResponseRates({state, commit}, period) {
      $.get('/analysis/comparison/rolling_response_rates', { period_months: period.months } , function(data) {
        state.rollingResponseRates = data
      }).then(function(data){
        if(!period.initial){
          commit('changePeriod', period)
        }
      })
    }
  }
})
