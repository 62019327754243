<template>
  <div class='question-statistics-container'>
    <div class="row">
      <div class='title col-9'>
        {{t('analysis.components.show.survey_question_statistics')}}
      </div>
      <div class="col-3 d-flex justify-content-end" >
        <export-comments-button :language="language" :filtered-department-ids="selectedDepartmentIds"/>
      </div>
    </div>
    <div class='row'>
      <div class='col-md-6 col-12'>
        <div class='time-period-select mb-2'>
          <span v-for='period in periodFilters' class='pointer' :class='{active: period.active}' @click='changeTimePeriod(period)'>
            {{period.label}}
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <span class='pointer expand-questions' @click="toggleAll">{{toggleAllText}}</span>
      </div>
    </div>
    <div v-if='visibleQuestions.length == 0' class='no-answers-placeholder'>
      {{t('components.analysis.components.survey_question_container.no_survey_answers')}}
    </div>
    <survey-question-row v-else v-for='question in visibleQuestions' :question='question' :key='question.id' ref="questionRows"/>
  </div>
</template>
<script>
  import SurveyQuestionRow from './SurveyQuestionRow'
  import ExportCommentsButton from '../ExportCommentsButton.vue'
  export default {
    props: ['managerId', 'language', 'selectedDepartmentIds'],

    data() {
      return {
        toggledAll: false
      }
    },

    created() {
      this.$store.dispatch('fetchQuestionStatistics')
      this.$store.commit('setManagerId', this.managerId)
    },

    computed: {
      periodFilters() {
        return this.$store.state.surveyQuestionTimePeriodFilters
      },

      filteredQuestionDates() {
        return this.$store.getters.filteredQuestionDates
      },

      toggleAllText() {
        if(!this.toggledAll) {
          return this.t('analysis.components.show.expand_all_questions')
        }
        else {
          return this.t('analysis.components.show.collapse_all_questions')
        }
      },

      visibleQuestions() {
        return this.$store.state.statisticQuestions.
          filter( q => {
            const validAnswers = q.answers.filter(a => this.filteredQuestionDates.findIndex(d => d == a.cycle_date) > -1 )
            return validAnswers.length > 0
          }).
          sort((q1, q2) => {
            const q1ValidAnswers = q1.answers.filter(a => this.filteredQuestionDates.findIndex(d => d == a.cycle_date) > -1 )
            const q2ValidAnswers = q2.answers.filter(a => this.filteredQuestionDates.findIndex(d => d == a.cycle_date) > -1 )
            return q2ValidAnswers.length - q1ValidAnswers.length
          })
      }
    },

    methods: {
      changeTimePeriod(timePeriod) {
        this.$store.commit('changeQuestionTimePeriod', timePeriod)
      },
      toggleAll() {
        if(this.$refs.questionRows) {
          this.toggledAll = !this.toggledAll
          this.$refs.questionRows.forEach(row => {
            row.toggleExpanded()
            row.toggleComments()
          })
        }
      }
    },

    components: { SurveyQuestionRow }
  }
</script>
