<template>
  <div class='chart-outer-container'>
    <div class='row'>
      <div class='visual-data-table'>
        
        <div class='table-row visual-data-table-header-row'>
          <div class='table-header-cell dimension sticky empty'></div>            
          <div class='table-header-cell' v-bind:title="totalDeltaFormula()">
            <div class='response-rate card card-dashboard' id='total_card'>
              <div class='response-rate-title'>
                <strong>{{t('analysis.summary.index.response')}}</strong>
              </div>
              <ResponseRateDiagram :color='totalColor()' :value='totalValue()' :percentagePoint="true"/>
            </div>
            <div class='table-header-cell-title'>
              <strong>
                {{ t('components.analysis.comparisons.chart_container.total') }}
              </strong>
            </div>
          </div>

          <div v-for='group in analysisGroups' class='table-header-cell' v-bind:title="groupDeltaFormula(group)">
            <div class="response-rate card card-dashboard" :id="`group-${group.id}`">
              <div class="response-rate-title">
                <strong>{{t('analysis.summary.index.response')}}</strong>
              </div>
              <ResponseRateDiagram :color='analysisGroupColor(group)' :value='analysisGroupValue(group)' :percentagePoint="true"/>
            </div>
            <div class='table-header-cell-title'>
              <strong>
                {{ group.name }}
              </strong>
            </div>
          </div>

          <div v-for='department in departments' class='table-header-cell' v-bind:title="departmentDeltaFormula(department)">
            <div class="response-rate card card-dashboard" :id="`department-${department.id}`">
              <div class="response-rate-title">
                <strong>{{t('analysis.summary.index.response')}}</strong>
              </div>
              <ResponseRateDiagram :color='departmentColor(department)' :value='departmentValue(department)' :percentagePoint="true"/>
            </div>
            <div class='table-header-cell-title'>
              <strong>
                {{ department.name }}
              </strong>
            </div>
          </div>
        </div>

        <div class='table-row' v-for='metric in metrics'>
          <div class='table-cell dimension special sticky'>
            <div class='title'>
              {{ metric.name }}
            </div>
          </div>

          <rolling-data-value v-bind:rollingData="rollingDelta('total', { metricId: metric.id })"/>

          <rolling-data-value
            v-for='analysisGroup in analysisGroups'
            v-bind:key='"total-ag-" + analysisGroup.id'
            v-bind:rollingData="rollingDelta('analysis_group', { metricId: metric.id, analysisGroupId: analysisGroup.id })"/>

          <rolling-data-value
            v-for='department in departments'
            v-bind:key='"total-department-" + department.id'
            v-bind:rollingData="rollingDelta('department', { metricId: metric.id, departmentId: department.id })"/>

        </div>

        <div class='table-row' v-for='(component, index) in components'>
          <div class='table-cell dimension sticky' v-bind:class="{ 'cover-top': (index == 0) }">
            <div class='title'>
              {{ component.name }}
            </div>
          </div>

          <rolling-data-value v-bind:rollingData="rollingDelta('total', { componentId: component.id })"/>

          <rolling-data-value
            v-for='analysisGroup in analysisGroups'
            v-bind:key='"ag-" + analysisGroup.id'
            v-bind:rollingData="rollingDelta('analysis_group', { componentId: component.id, analysisGroupId: analysisGroup.id })"/>

          <rolling-data-value
            v-for='department in departments'
            v-bind:key='"department-" + department.id'
            v-bind:rollingData="rollingDelta('department', { componentId: component.id, departmentId: department.id })"/>

        </div>

        <div class='table-row' v-for='npsComponent in npsComponents'>
          <div class='table-cell dimension special sticky'>
            <div class='title'>
              {{ npsComponent.name }}
            </div>
          </div>

          <rolling-data-value v-bind:rollingData="rollingDelta('total', { componentId: npsComponent.id })"/>

          <rolling-data-value
            v-for='analysisGroup in analysisGroups'
            v-bind:key='"ag-" + analysisGroup.id'
            v-bind:rollingData="rollingDelta('analysis_group', { componentId: npsComponent.id, analysisGroupId: analysisGroup.id })"/>

          <rolling-data-value
            v-for='department in departments'
            v-bind:key='"department-" + department.id'
            v-bind:rollingData="rollingDelta('department', { componentId: npsComponent.id, departmentId: department.id })"/>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import RollingDataValue from './RollingDataValue'
  import ResponseRateDiagram from '../ResponseRateDiagram'
  import moment from "moment";

  export default {
    computed: {
      filteredDepartmentIds(){
        return this.$store.state.selectedDepartmentIds
      },
      filteredAnalysisGroupIds(){
        return this.$store.state.selectedAnalysisGroupIds
      },
      availableAnalysisGroups(){
        return Object.values(JSON.parse(this.$store.state.availableAnalysisGroups))
      },
      analysisGroups() {
        return this.availableAnalysisGroups.filter(a => {
          if (this.filteredAnalysisGroupIds.length == 0) {
            return this.filteredDepartmentIds.length == 0 ? true : false
          }

          return (
            this.filteredAnalysisGroupIds.findIndex(id => {
              return a.id === id;
            }) > -1
          )
        })
      },
      departments() {
        return this.$store.state.departments.filter(d => {
          if (this.filteredDepartmentIds.length == 0) {
            return this.filteredAnalysisGroupIds.length == 0 ? true : false
          }
          return (
            this.filteredDepartmentIds.findIndex(id => {
              return d.id === id;
            }) > -1
          )
        })
      },
      metrics() {
        return this.$store.state.metrics
      },
      components() {
        return this.$store.state.components
      },
      npsComponents() {
        return this.$store.state.npsComponents
      },
      filteredDepartmentValues() {
        return this.$store.state.rollingResponseRates.departments
      },
      filteredGroupValues() {
        return this.$store.state.rollingResponseRates.groups
      },
      totalValues() {
        return this.$store.state.rollingResponseRates.total_values
      },
      currentCycleDate() {
        return moment(this.$store.state.rollingResponseRates.current_cycle_date).format('DD.MM.YYYY')
      },
      comparisonCycleDate() {
        if(this.$store.state.rollingResponseRates.filtered_cycle_date) {
          return moment(this.$store.state.rollingResponseRates.filtered_cycle_date).format('DD.MM.YYYY')
        } else {
          return 'No Data'
        }
      },
      totalCurrentValue() {
        if(this.$store.state.rollingResponseRates.total_values) {
          return this.$store.state.rollingResponseRates.total_values.total_current_value
        }
      },
      totalComparisonValue() {
        if(this.$store.state.rollingResponseRates.total_values) {
          return this.$store.state.rollingResponseRates.total_values.total_comparison_value
        }
      }
    },
    methods: {
      rollingDelta(type, rollingData={}) {
        let metric
        let rollingDataResponse = {
          deltaValue: null,
          deltaFormula: null
        }

        switch (type) {
          case "total":
            if (rollingData.metricId) {
              metric = this.$store.state.rollingMetrics.find(f => f.department_id == null && f.metric_id == rollingData.metricId)
            } else {
              metric = this.$store.state.rollingComponents.find(f => f.department_id == null && f.component_id == rollingData.componentId)
            }
            
            rollingDataResponse.deltaValue = this.getDeltaValue(metric)
            rollingDataResponse.deltaFormula = this.getDeltaFormula(metric)
            
            break

          case "analysis_group":
            if (rollingData.metricId) {
              metric = this.$store.state.rollingMetrics.find(f => f.department_id == null && f.analysis_group_id == rollingData.analysisGroupId && f.metric_id == rollingData.metricId)
            } else {
              metric = this.$store.state.rollingComponents.find(f => f.department_id == null && f.analysis_group_id == rollingData.analysisGroupId && f.component_id == rollingData.componentId)
            }
            
            rollingDataResponse.deltaValue = this.getDeltaValue(metric)
            rollingDataResponse.deltaFormula = this.getDeltaFormula(metric)

            break

          case "department":
            if (rollingData.metricId) {
              metric = this.$store.state.rollingMetrics.find(f => f.department_id == rollingData.departmentId && f.metric_id == rollingData.metricId)
            } else {
              metric = this.$store.state.rollingComponents.find(f => f.department_id == rollingData.departmentId && f.component_id == rollingData.componentId)
            }

            rollingDataResponse.deltaValue = this.getDeltaValue(metric)
            rollingDataResponse.deltaFormula = this.getDeltaFormula(metric)

            break
        }

        return rollingDataResponse
      },
      getDeltaValue(metric) {
        if (!metric || isNaN(parseInt(metric.value)) || isNaN(parseInt(metric.previous_value))) {
          return null
        } else {
          return metric.value - metric.previous_value
        }
      },
      getDeltaFormula(metric) {
        if (!metric || isNaN(parseInt(metric.value)) || isNaN(parseInt(metric.previous_value))) {
          return null
        } else {
          const cycle_date = moment(metric.cycle_date).format('DD.MM.YYYY')
          const previous_cycle_date = metric.previous_cycle_date ? moment(metric.previous_cycle_date).format('DD.MM.YYYY') : 'No Data'

          return `Score ${metric.value} on ${cycle_date}.  -  Score ${metric.previous_value} on ${previous_cycle_date}.`
        }
      },
      totalDeltaFormula(){
        if (isNaN(parseInt(this.totalComparisonValue)) || isNaN(parseInt(this.totalCurrentValue))) {
          return null
        } else {
          return `${this.totalCurrentValue}% on ${this.currentCycleDate}.  -  ${this.totalComparisonValue}% on ${this.comparisonCycleDate}.`
        }
      },
      departmentDeltaFormula(department){
        if(this.filteredDepartmentValues && this.filteredDepartmentValues[department.id][2]) {
          const current_value = this.filteredDepartmentValues[department.id][2].current
          const comparison_value = this.filteredDepartmentValues[department.id][2].comparison
          return `${current_value}% on ${this.currentCycleDate}.  -  ${comparison_value}% on ${this.comparisonCycleDate}.`
        } else {
          return null
        }
      },
      groupDeltaFormula(group){
        if(this.filteredGroupValues && this.filteredGroupValues[group.id][2]) {
          const current_value = this.filteredGroupValues[group.id][2].current
          const comparison_value = this.filteredGroupValues[group.id][2].comparison
          return `${current_value}% on ${this.currentCycleDate}.  -  ${comparison_value}% on ${this.comparisonCycleDate}.`
        } else {
          return null
        }
      },
      departmentValue(department) {
        if( this.filteredDepartmentValues && this.filteredDepartmentValues[department.id][0]) {
          return this.filteredDepartmentValues[department.id][0]
        } else {
          return 0
        }
      },
      departmentColor(department) {
        if( this.filteredDepartmentValues && this.filteredDepartmentValues[department.id][1]) {
          return this.filteredDepartmentValues[department.id][1]
        } else {
          return '#EA0000'
        }
      },
      analysisGroupValue(group) {
        if( this.filteredGroupValues && this.filteredGroupValues[group.id][0]) {
          return this.filteredGroupValues[group.id][0]
        } else {
          return 0
        }
      },
      analysisGroupColor(group) {
        if( this.filteredGroupValues && this.filteredGroupValues[group.id][1]) {
          return this.filteredGroupValues[group.id][1]
        } else {
          return '#EA0000'
        }
      },
      totalValue() {
        if( this.totalValues && this.totalValues.total_value) {
          return this.totalValues.total_value
        } else {
          return 0
        }
      },
      totalColor() {
        if( this.totalValues && this.totalValues.total_color) {
          return this.totalValues.total_color
        } else {
          return '#EA0000'
        }
      }
    },

    components: {
      RollingDataValue
    }
  }
</script>
