<template>
  <div class="row">
    <div class="visual-data-table">
      <div class="table-row visual-data-table-header-row">
        <div class="table-header-cell">
          <DepartmentOverview :color="totalValues.response_rate.color" :value="totalValues.response_rate.value" />
          <div class="table-header-cell-title">
            <strong>{{ t('analysis.organizations.show.total') }}</strong>
          </div>
        </div>

        <div v-for="group in sortedAnalysisGroups" :key="'group-' + group.id" class="table-header-cell">
          <DepartmentOverview :color="group.color" :value="group.value" />
          <div class="table-header-cell-title">
            <strong>{{ group.name }}</strong>
          </div>
        </div>

        <div v-for="department in sortedDepartments" :key="'department-' + department.id" class="table-header-cell">
          <DepartmentOverview :color="department.color" :value="department.value" />
          <div class="table-header-cell-title">
            <strong>{{ department.name }}</strong>
          </div>
        </div>
      </div>

      <div v-for="metric in metrics" :key="'metric-' + metric.id" class="table-row">
        <div class="table-cell dimension special sticky">
          <div class="title">{{ metric.name }}</div>
        </div>
        <ValueCell :color="totalValues.metrics[metric.id].color"
                   :dataExists="totalValues.metrics[metric.id].data_exists"
                   :value="totalValues.metrics[metric.id].value" />

          <div v-for="group in sortedAnalysisGroups" :key="'group-' + group.id">
          <ValueCell :color="analysisGroups[group.id].metrics[metric.id].color"
                     :dataExists="analysisGroups[group.id].metrics[metric.id].data_exists"
                     :value="analysisGroups[group.id].metrics[metric.id].value"/>
        </div>

        <div v-for="department in sortedDepartments" :key="'department-' + department.id">
          <ValueCell :color="departments[department.id].metrics[metric.id].color"
                     :dataExists="departments[department.id].metrics[metric.id].data_exists"
                     :value="departments[department.id].metrics[metric.id].value" />
        </div>
      </div>

      <div v-for="component in components" :key="'component-' + component.id" class="table-row">
        <div class="table-cell dimension sticky">
          <div class="title">{{ component.name }}</div>
        </div>
        <ValueCell :color="totalValues.components[component.id].color"
                   :dataExists="totalValues.components[component.id].data_exists"
                   :value="totalValues.components[component.id].value" />

          <div v-for="group in sortedAnalysisGroups" :key="'group-' + group.id">
          <ValueCell :color="analysisGroups[group.id].components[component.id].color"
                     :dataExists="analysisGroups[group.id].components[component.id].data_exists"
                     :value="analysisGroups[group.id].components[component.id].value"/>
        </div>

        <div v-for="department in sortedDepartments" :key="'department-' + department.id">
          <ValueCell :color="departments[department.id].components[component.id].color" 
                     :dataExists="departments[department.id].components[component.id].data_exists"
                     :value="departments[department.id].components[component.id].value" />
        </div>
      </div>

      <div v-for="component in npsComponents" :key="'npsComponent-' + component.id" class="table-row nps-row">
        <div class="table-cell dimension special sticky nps-label">
          <div class="title">
            {{ component.name }}
            <span class="ml-1 tooltip-icon">
              <i v-tooltip="{ content: component.description }" class="fa fa-question-circle"></i>
            </span>
          </div>
        </div>
        <NpsComponentCell :colorValue="totalValues.nps_components[component.id].color"
                          :value="totalValues.nps_components[component.id].value"
                          :classValue="totalValues.nps_components[component.id].class_value"
                          :heightValue="totalValues.nps_components[component.id].height_value"/>

        <div v-for="group in sortedAnalysisGroups" :key="'group-' + group.id">
          <NpsComponentCell :colorValue="analysisGroups[group.id].nps_components[component.id].color"
                            :value="analysisGroups[group.id].nps_components[component.id].value"
                            :classValue="analysisGroups[group.id].nps_components[component.id].class_value"
                            :heightValue="analysisGroups[group.id].nps_components[component.id].height_value"/>
        </div>
        <div v-for="department in sortedDepartments" :key="'department-' + department.id">
          <NpsComponentCell :colorValue="departments[department.id].nps_components[component.id].color"
                            :value="departments[department.id].nps_components[component.id].value"
                            :classValue="departments[department.id].nps_components[component.id].class_value"
                            :heightValue="departments[department.id].nps_components[component.id].height_value"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentOverview from '../DepartmentOverview.vue';
import ValueCell from './ValueCell.vue';
import NpsComponentCell from './NpsComponentCell.vue';

export default {
  components: {
    DepartmentOverview,
    ValueCell,
    NpsComponentCell
  },
  computed: {
    filteredDepartmentIds(){
      return this.$store.state.selectedDepartmentIds
    },
    filteredAnalysisGroupIds(){
      return this.$store.state.selectedAnalysisGroupIds
    },
    analysisGroups() {
      const groups = this.$store.state.analysisGroups

      return Object.values(groups).reduce((filtered, group) => {
        const g = groups[group.id]
        
        if (this.filteredAnalysisGroupIds.length === 0) {
          if (this.filteredDepartmentIds.length == 0) {
            filtered[group.id] = g;
          }
        } else if (this.filteredAnalysisGroupIds.includes(g.id)) {
          filtered[group.id] = g
        }
        return filtered
      }, {})
    },
    sortedAnalysisGroups() {
      const groupsArray = Object.values(this.analysisGroups)
      groupsArray.sort((a, b) => a.name.localeCompare(b.name))

      return groupsArray
    },
    departments() {
      const departments = this.$store.state.departments
  
      return Object.keys(departments).reduce((filtered, key) => {
        const d = departments[key]
        
        if (this.filteredDepartmentIds.length === 0) {
          if (this.filteredAnalysisGroupIds.length == 0) {
            filtered[key] = d;
          }
        } else if (this.filteredDepartmentIds.includes(d.id)) {
          filtered[key] = d
        }
        
        return filtered
      }, {})
    },
    sortedDepartments() {
      const departmentArray = Object.values(this.departments)
      departmentArray.sort((a, b) => a.name.localeCompare(b.name))

      return departmentArray
    },
    metrics() {
      return this.$store.state.metrics
    },
    components() {
      return this.$store.state.components
    },
    npsComponents(){
      return this.$store.state.npsComponents
    },
    totalValues() {
      return this.$store.state.totalValues
    }
  }
}
</script>