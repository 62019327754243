import Vue from 'vue'
import Vuex from 'vuex'
import { AUTH_TOKEN } from '../../helpers/globals'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    analysisGroups: [],
    departments: [],
    metrics: [],
    components: [],
    npsComponents: [],
    rollingResponseRates: [],
    selectedDepartments: [],
    filteredDepartments: [],
    selectedDepartmentIds: [],
    selectedAnalysisGroupIds: [],
    selectedAnalysisGroups: [],
    filteredAnalysisGroups: [],
    availableDepartments: [],
    availableAnalysisGroups: [],
    totalValues: []
  },
  actions: {
    postFilterData({ commit, state }, data) {
      const departmentIds = data.department_ids
      const groupIds = data.analysis_group_ids
      state.selectedDepartmentIds = departmentIds
      state.selectedAnalysisGroupIds = groupIds

      axios.post(
        '/sessions/filtered_object_ids',
        {
          authenticity_token: AUTH_TOKEN,
          department_ids: departmentIds,
          analysis_group_ids: groupIds
        }
      )
    }
  }
})