<template>
  <div id='organization'>
    <div class="row filter-container">
      <FilterResultsDropdown />
    </div>
    <TableContainer />
  </div>
</template>

<script>
import store from './store';
import TableContainer from './TableContainer.vue'
import FilterResultsDropdown from './FilterResultsDropdown.vue';
export default {
  store: store,
  props: ['analysisGroups',
          'departments',
          'components',
          'npsComponents',
          'availableDepartments',
          'availableAnalysisGroups',
          'metrics',
          'totalValues',
          'selectedDepartmentIds',
          'selectedAnalysisGroupIds'],
  created() {
    this.$store.state.metrics = this.metrics
    this.$store.state.departments = this.departments
    this.$store.state.analysisGroups = this.analysisGroups
    this.$store.state.components = this.components
    this.$store.state.npsComponents = this.npsComponents
    this.$store.state.availableDepartments = this.availableDepartments
    this.$store.state.availableAnalysisGroups = this.availableAnalysisGroups
    this.$store.state.selectedDepartmentIds = this.selectedDepartmentIds
    this.$store.state.selectedAnalysisGroupIds = this.selectedAnalysisGroupIds
    this.$store.state.totalValues = this.totalValues
  },
  components: {
    TableContainer,
    FilterResultsDropdown
  }
}
</script>