<script>
import store from './store'
import FilterResultsDropdown from '../FilterResultsDropdown.vue';
import { includes } from 'lodash'
export default {
  extends: FilterResultsDropdown,
  store: store,

  created() {
    this.initDepartmentsAndAnalysisGroups()
    this.initSurveyQuestionDepartmentIds()
    this.initSelectedAndFilteredAnalysisGroups()
  },

  computed: {
    computedClass() {
      return 'filter-results-dropdown'
    }
  },

  methods: {
    initSurveyQuestionDepartmentIds() {
      if (this.selectedThingCount > 0) {
        this.$store.dispatch('setCheckedThings', {
          departmentIds: this.selectedDepartmentIds,
          groupIds: this.selectedAnalysisGroupIds
        })
      } else if (this.selectedThingCount == 0) {
        this.$store.dispatch('setDepartmentChecked', null)
      }
    },

    postFilterData() {
      const department_ids = this.selectedDepartments.length > 0 ? this.selectedDepartments.map(d => d.id).sort() : []
      const group_ids = this.selectedAnalysisGroups.length > 0 ? this.selectedAnalysisGroups.map(g => g.id).sort() : []

      this.$store.dispatch('postFilterData', {
        department_ids: department_ids,
        analysis_group_ids: group_ids,
        section: this.section,
      }).then(() => this.$store.dispatch('setCheckedThings', { departmentIds: department_ids, groupIds: group_ids }))

      this.filteredDepartments = Object.assign([], this.selectedDepartments)
      this.filteredAnalysisGroups = Object.assign([], this.selectedAnalysisGroups)
      this.dropdownVisible = false
    },

    initDepartmentsAndAnalysisGroups() {
      this.departments = Object.values(JSON.parse(this.$store.state.availableDepartments))
      this.analysisGroups = Object.values(JSON.parse(this.$store.state.availableAnalysisGroups))
    },

    initSelectedAndFilteredDepartments() {
      if(this.$store.state.surveyQuestionDepartmentIds) {
        this.selectedDepartments = this.departments.filter(d => includes(this.$store.state.surveyQuestionDepartmentIds, d.id))
        this.filteredDepartments = Object.assign([], this.selectedDepartments)
      }
    },

    initSelectedAndFilteredAnalysisGroups() {
      if(this.$store.state.selectedAnalysisGroupIds) {
        this.selectedAnalysisGroups = this.analysisGroups.filter(g => includes(this.$store.state.selectedAnalysisGroupIds, g.id))
        this.filteredAnalysisGroups = Object.assign([], this.selectedAnalysisGroups)
      }
    }
  }
}
</script>
