import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

const AUTH_TOKEN = $('[name="csrf-token"]')[0].content

export default new Vuex.Store({
  state: {
    availableAnalysisGroups: [],
    availableDepartments: [],
    formErrors: {},
    loading: true
  },

  actions: {
    postFilterData({ commit, state }, data) {
      state.formErrors = {}

      const departmentIds = data.department_ids
      const groupIds = data.analysis_group_ids

      axios.post(
        '/sessions/filtered_object_ids',
        {
          authenticity_token: AUTH_TOKEN,
          department_ids: departmentIds,
          analysis_group_ids: groupIds
        }
      ).then((response) => {
        window.location = `/analysis/${data.section}`
      });
    }
  }
})
