<template>
  <div id='component-container'>
    <div class='row flex'>
      <div class='header-navigation col-9'>
        <div class='dropdown component-select'>
          <button class='btn btn-default dropdown-toggle' id='component-select' type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
            {{currentComponent.name}}
            <span class='caret'></span>
          </button>
          <ul class='dropdown-menu' aria-labelledby='component-select'>
            <li v-for='selectableComponent in componentsForSelect' class='{active: component.id == selectableComponent.id}'>
              <a :href='selectableComponent.path'>
                {{selectableComponent.name}}
              </a>
            </li>
          </ul>
        </div>
        <a v-if='previousComponent' :href='previousComponent.path' class='header-navigation-link'>
          <i class='fa-chevron-left fa'></i>
        </a>
        <a v-if='nextComponent' :href='nextComponent.path' class='header-navigation-link'>
          <i class='fa-chevron-right fa'></i>
        </a>
      </div>
      <div class='col-3'>
        <div class='float-right' style="position: relative;">
          <component-filter-results-dropdown
            :section="'components'"
            :availableAnalysisGroups="this.$store.state.availableAnalysisGroups"
            :availableDepartments="this.$store.state.availableDepartments"
            :selectedDepartmentIds="this.$store.state.surveyQuestionDepartmentIds"
            :selectedAnalysisGroupIds="this.$store.state.selectedAnalysisGroupIds"/>
        </div>
      </div>
    </div>
    <div class='divider'></div>
    <div class='summary-content'>
      <div class='row'>
        <div class='col-9'>
          <div class='title'>{{currentComponent.name}}</div>
          <div class='chart'>
            <small-progress-bar-chart v-if='rollingComponent' :value='rollingComponent.value' />
          </div>
          <div class='description'>
            {{currentComponent.description}}
          </div>
          <div class='more-info'>
            <a :href='component.read_more_url' target='_blank'>
              {{t('analysis.components.show.more_info')}}
            </a>
          </div>
        </div>
        <div class='col-3'>
          <div class='float-right'>
            <component-learn-more-button
              :button-title="t('analysis.components.show.ask_question')"
              :component-id='currentComponent.id'
              :next-survey-date='nextSurveyDate'
              :departments='departments' />
            <div class='help-popover more-info'>
              <a href='#'>{{t('analysis.components.show.what_is_open_question')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='divider'></div>
    <div class='chart-block-container'>
      <component-line-chart-block
        v-if='rollingComponent.value'
        :component_id='currentComponent.id'
        :labels='labels'
        :benchmark='currentComponent.benchmark'/>
      <div v-else class='no-data mt-3'>
        <div class='empty-chart-icon'>
          <img :src='noResultsImagePath'/>
        </div>
        {{t('not_enough_data')}}
      </div>
    </div>
    <div class='feedback-block-container md-2'>
      <div class='title'>
        {{t('analysis.components.show.feedback')}}
      </div>
      <div class='block'>
        <component-feedback-container :component-id='currentComponent.id'/>
      </div>
    </div>
    <div id='improvement-suggestions' class='improvement-suggestions-block'>
      <div class='title'>
        {{t('analysis.components.show.tips_and_tricks')}}
      </div>
      <div class='block'>
        <component-improvement-suggestions-block :passed-suggestions='passedSuggestions' />
      </div>
    </div>
    <slot></slot>
    <component-survey-question-container
      :manager-id='manager_id'
      :language="language"
      :selected-department-ids="filteredDepartmentIdsFromGroupsAndDepartments" />
  </div>
</template>
<script>
  import store from './store'
  import ComponentFilterResultsDropdown from './FilterResultsDropdown.vue'
  import ExportCommentsButton from '../ExportCommentsButton.vue'
  import ComponentSurveyQuestionContainer from './SurveyQuestionContainer.vue'
  import ComponentImprovementSuggestionsBlock from './ImprovementSuggestionsBlock.vue'
  import ComponentFeedbackContainer from './FeedbackContainer.vue'
  import ComponentLineChartBlock from './LineChartBlock.vue'
  import ComponentLearnMoreButton from './LearnMoreButton.vue'
  import SmallProgressBarChart from '../../charts/SmallProgressBarChart.vue'
  import { includes } from 'lodash'

  export default {
    store: store,
    props: [
      'component',
      'departments',
      'availableDepartments',
      'availableAnalysisGroups',
      'selectedDepartmentIds',
      'selectedAnalysisGroupIds',
      'rollingComponent',
      'labels',
      'benchmark',
      'componentsForSelect',
      'nextSurveyDate',
      'passedSuggestions',
      'language',
      'manager_id'
    ],

    created() {
      this.$store.state.rollingComponent = this.rollingComponent
      this.$store.state.componentId = this.component.current_component.id
      this.$store.state.currentComponent = this.component.current_component
      this.$store.state.previousComponent = this.component.previous_component
      this.$store.state.nextComponent = this.component.next_component
      this.$store.state.nextSurveyDate = this.component.next_survey_date
      this.$store.state.labels = this.labels
      this.$store.state.benchmark = this.benchmark
      this.$store.state.availableAnalysisGroups = this.availableAnalysisGroups
      this.$store.state.availableDepartments = this.availableDepartments
      this.$store.state.surveyQuestionDepartmentIds = this.selectedDepartmentIds
      this.$store.state.selectedAnalysisGroupIds = this.selectedAnalysisGroupIds

      this.$store.dispatch('fetchSurveyDates').then(() => {
        this.$store.dispatch('setCheckedThings', { 
          departmentIds: this.selectedDepartmentIds,
          groupIds: this.selectedAnalysisGroupIds 
        })
      })
    },
    computed: {
      currentComponent(){
        return this.$store.state.currentComponent
      },
      previousComponent(){
        return this.$store.state.previousComponent
      },
      nextComponent(){
        return this.$store.state.nextComponent
      },
      noResultsImagePath() {
        return "/assets/no-results-icon.svg"
      },
      filteredDepartmentIdsFromGroupsAndDepartments() {
        const groups = JSON.parse(this.$store.state.availableAnalysisGroups).filter((g) => includes(this.$store.state.selectedAnalysisGroupIds, g.id))
        const departmentIdsFromGroups = groups.map((g) => g.departments.map((d) => d.id)).flat()
        const mergedDepartmentIds = departmentIdsFromGroups.concat(this.$store.state.surveyQuestionDepartmentIds)

        if (mergedDepartmentIds.length > 0) {
          return [...new Set(mergedDepartmentIds)]
        } else {
          return null
        }
      }
    },
    components: {
      ComponentFilterResultsDropdown,
      ExportCommentsButton,
      ComponentSurveyQuestionContainer,
      ComponentImprovementSuggestionsBlock,
      ComponentFeedbackContainer,
      ComponentLineChartBlock,
      ComponentLearnMoreButton,
      SmallProgressBarChart
    }
  }
</script>
