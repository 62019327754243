<script>
import store from './store'
import FilterResultsDropdown from '../FilterResultsDropdown.vue';
import { includes } from 'lodash'
export default {
  extends: FilterResultsDropdown,
  store: store,
  methods: {
    initDepartmentsAndAnalysisGroups() {
      this.departments = Object.values(JSON.parse(this.$store.state.availableDepartments))
      this.analysisGroups = Object.values(JSON.parse(this.$store.state.availableAnalysisGroups))
    },
    initSelectedAndFilteredDepartments() {
      if(this.$store.state.selectedDepartmentIds) {
        this.selectedDepartments = this.departments.filter(d => includes(this.$store.state.selectedDepartmentIds, d.id))
        this.filteredDepartments = Object.assign([], this.selectedDepartments)
      }
    },
    initSelectedAndFilteredAnalysisGroups() {
      if(this.$store.state.selectedAnalysisGroupIds) {
        this.selectedAnalysisGroups = this.analysisGroups.filter(g => includes(this.$store.state.selectedAnalysisGroupIds, g.id))
        this.filteredAnalysisGroups = Object.assign([], this.selectedAnalysisGroups)
      }
    }
  }
}
</script>
