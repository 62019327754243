<template>
  <div id='ad-hoc-surveys'>
    <loading-indicator :visible='isLoading' />
    <router-view v-if='!isLoading'></router-view>
  </div>
</template>
<script>
  import router from './router'
  import store from './store'
  import LoadingIndicator from '../utilityComponents/LoadingIndicator'

  export default {
    props: ['localeOptions', 'departmentIds', 'analysisGroupIds'],
    store: store,
    router: router,
    created() {
      this.$store.state.localeOptions = this.localeOptions;
      this.$store.state.filteredDepartmentIds = this.departmentIds || [];
      this.$store.state.filteredAnalysisGroupIds = this.analysisGroupIds || [];
      this.$store.dispatch('initialize').
        catch((response) => {
          this.$root.$emit('flash', { notice: this.t('components.errors.load_error'), type: 'error' })
        })
    },

    computed: {
      isLoading() {
        return this.$store.state.loading
      }
    },

    components: { LoadingIndicator }
  }
</script>
