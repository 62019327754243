<template>
  <div class="survey-show" v-if="!loading">
    <div class="main-title">
      {{ survey.name }}
      <div class="description">{{ survey.description }}</div>
    </div>
    <div class="info-wrapper">
      <div class="info">
        <div class="value">{{ formattedSentAt }}</div>
        <div class="label">Status</div>
      </div>
      <div class="info">
        <div class="value">{{ survey.questions.length }}</div>
        <div class="label">Questions</div>
      </div>
      <div class="info cursor">
        <div v-tooltip="groups" class="value">{{ survey.recipients_count }}</div>
        <div class="label">Receivers</div>
      </div>

      <div class="info">
        <div class="value">{{ survey.responses_count }}</div>
        <div class="label">Answered</div>
      </div>
      <div class="info">
        <div class="value">{{ responseRate }}%</div>
        <div class="label">Response rate</div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="main-title small border-0">Survey questions</div>
      <filter-results-dropdown 
        :available-departments="this.$store.state.departments"
        :available-analysis-groups="this.$store.state.analysisGroups"
        :selected-department-ids="this.$store.state.filteredDepartmentIds"
        :selected-analysis-group-ids="this.$store.state.filteredAnalysisGroupIds"
        section="ad_hoc"
      />
    </div>
    <div class="questions">
      <question-statistics
        :survey-sent="survey.sent_at ? true : false"
        v-for="(question, index) in survey.questions"
        :question="question"
        :question-number="index + 1"
        :question-responses="questionResponses[question.id] || []"
        :key="question.id"
      />
    </div>
    <div class="action-buttons pt-3">
      <router-link
        class="btn btn-primary btn-bordered pointer mr-3"
        :to="{name: 'surveys'}"
      >{{t('back')}}</router-link>
      <router-link
        data-action="edit_survey"
        class="btn btn-primary pointer mr-3"
        v-if="survey.can_update"
        :to='{name: "editSurvey", params: { id: survey.id}}'
      >Edit</router-link>
      <span
        data-action="send_survey"
        class="btn btn-primary pointer mr-3"
        v-if="survey.can_send_survey"
        @click="openModal"
      >Select recipients</span>
      <span data-action="destroy_survey" class="btn btn-danger pointer" v-if="survey.can_destroy" @click="destroySurvey">Delete</span>
    </div>
    <send-survey-modal v-if="modalOpen" :survey="survey" @update="closeModal" @close="closeModal"/>
  </div>
</template>
<script>
import SendSurveyModal from "./SendSurveyModal";
import QuestionStatistics from "./QuestionStatistics";
import FilterResultsDropdown from "./FilterResultsDropdown";

export default {
  created() {
    this.fetchSurvey();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchSurvey"
  },
  data() {
    return {
      survey: null,
      questionResponses: {},
      loading: true,
      modalOpen: false
    };
  },
  computed: {
    groups() {
      return this.survey.departments.map(d => d.name).join(", ")
    },
    formattedSentAt() {
      if (this.survey.sent_at) {
        return (
          this.t("components.ad_hoc_surveys.survey_row.sent") +
          " " +
          this.l(this.survey.sent_at, { format: "time.formats.full" })
        );
      } else if (this.survey.sending_scheduled_at) {
        return (
          this.t("components.ad_hoc_surveys.survey_row.scheduled") +
          " " +
          this.l(this.survey.sending_scheduled_at, {
            format: "time.formats.full"
          })
        );
      } else {
        return this.t("components.ad_hoc_surveys.survey_row.not_sent");
      }
    },
    responseRate() {
      if (this.survey.recipients_count) {
        return Math.round(
          (100 * this.survey.responses_count) / this.survey.recipients_count
        );
      } else {
        return 0;
      }
    }
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    fetchSurvey() {
      const surveyId =
        this.$route.params.id && parseInt(this.$route.params.id, 10);
      this.loading = true;
      if (surveyId) {
        this.$store
          .dispatch("fetchSurveyStat", surveyId)
          .then(({ data }) => {
            this.survey = data;
            const questionResponses = {};
            data.question_responses.forEach(qr => {
              if (!questionResponses[qr.question_id]) {
                questionResponses[qr.question_id] = [];
              }
              questionResponses[qr.question_id].push(qr);
            });
            this.questionResponses = questionResponses;
            this.loading = false;
          })
          .catch(({ response }) => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    destroySurvey() {
      if (
        confirm(
          this.t("components.ad_hoc_surveys.survey_row.delete_confirmation")
        )
      ) {
        this.$store
          .dispatch("deleteSurvey", this.survey.id)
          .then(() => {
            this.$root.$emit("flash", {
              notice: this.t(
                "components.ad_hoc_surveys.survey_row.succesfully_deleted"
              )
            });
            this.$router.push({ name: "surveys" });
          })
          .catch(() =>
            this.$root.$emit("flash", {
              notice: this.t(
                "components.ad_hoc_surveys.survey_row.failed_to_delete"
              ),
              type: "error"
            })
          );
      }
    }
  },
  components: {
    QuestionStatistics,
    SendSurveyModal,
    FilterResultsDropdown
  }
};
</script>
<style>
.cursor{
  cursor:default;
}
</style>
